@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@1&family=Open+Sans:wght@300&display=swap');

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  background: rgb(245,237, 230);
  /*background-image: radial-gradient(circle, #ffffff, #f4edf2, #efdadc, #e4c9be, #cdbda2);
  */
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  min-height: 30vh;
  display: flex;
  font-family: 'DM Serif Text', serif;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
